import React, {useEffect, useState} from "react";
import { Link , useNavigate} from "react-router-dom";
import SocialMediaLinks from "./SocialMediaLinks";
import samplePDF from '../../src/GamewagrsPitchDeck.pdf';
import './Footer.css';

import './Header.css';
import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";

import fulllogo_transparent from "../images/fulllogo_transparent.png";

const Footer = () => {
const [show,setShow] = useState(false)
const navigate  = useNavigate()


  return ( 
    <>
      <section className='footer-sec'>
          <div className='container'>
            <div className="row">
              <div className="col-md-12 text-center">
                <div className='footer-logo'>
                  <img loading="lazy" src={fulllogo_transparent} alt="foot-logo"/>
                </div>
              </div>
              <div className="col-md-12 foot-list">
                  <ul className="footer-menu">
                      <li className="nav-item" onClick={()=>navigate("/ChallengeSomeone")} to='/ChallengeSomeone'>                                        
                          <Link  to="ChallengeSomeone" className="foot-link" href="#" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Challenge Someone</Link>
                      </li>
                      <li className="nav-item" onClick={()=>navigate("/data-policy")}>                                        
                          <Link  to="data-policy" className="foot-link" href="#" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">User Data Policy</Link>
                      </li> 
                      <li className="nav-item" onClick={()=>navigate("/terms-service")}>                                        
                          <Link  to="terms-service" className="foot-link" href="#" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"> Terms of Service</Link>
                      </li>  
                      <li className="nav-item" onClick={()=>navigate("/contact-us")}>                                        
                          <Link  to="ICOInformation" className="foot-link" href="#" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Contact Us</Link>
                      </li>
                      <li>
                          <a className="pitchdeck" href={samplePDF} target="_blank"
                            rel='noopener noreferrer'>
                            Pitch Deck
                          </a>
                      </li>
                                                                          
                  </ul>
                  <div className="footer-social-media-wrapper">
                    <SocialMediaLinks/>
                  </div>
                  <div className='copylink'>
                    <p>DuelCards L.L.C doing business as GameWagrs</p>
                  </div>
                  <div className='copylink'>
                    <p>Copyright © GameWagrs 2023, All Rights Reserved</p>
                  </div>
              </div>  
            </div>
          </div>
      </section>
    </>
  )
}
export default Footer