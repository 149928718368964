import React,{useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOGIN_SUCCESS } from '../constants/userConstants';
import { useDispatch } from 'react-redux';

export const VerifyUser = () => {
  const {state} = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: LOGIN_SUCCESS, payload: state });
    localStorage.setItem("nftuser", JSON.stringify({ ...state, password: "", expire: new Date().getDate() + 7 }));
    const timerId = setTimeout(() => { 
      navigate('/profile/3')
    }, 7000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  return (
    <div className='body-main'>
        <div className='thank-sec'>
            <div className='container'>
                <div className='thnkct'>
                <h2>Thank you for verifying your email!</h2>
                <h4>You will be redirected to link your Overwolf Account and start sending challenges!</h4>
                </div>
            </div>
        </div>
    </div>
  )
}

export default VerifyUser;
